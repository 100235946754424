// prettier-ignore
/* eslint-disable */
import axios from 'axios';
import Cookies from "js-cookie";

import queryParamseParser from "../helpers/queryParamseParser";

export const PRODUCTION_URL = process.env.REACT_APP_PRODUCTION_URL;
export const domain =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PRODUCTION_URL
    : "http://localhost/";
export const backendUrl = domain + "api";
export const mediaUrl = process.env.REACT_APP_MEDIA_URL;
export const TINY_MCE_API_KEY = process.env.REACT_APP_TINY_MCE_API_KEY;
const instance = axios.create({
  baseURL: backendUrl
});
const now = new Date();
const year = now.getFullYear();
const month = now.getMonth();
const day = now.getDate();
const expDate = new Date(year + 5, month, day);

if (!Cookies.get("rememberMe")) {
  Cookies.set("rememberMe", "false", { expires: 365, path: "" });
}

export const langs = {};
export const locales = [];
process.env.REACT_APP_ACTIVE_LOCALES.split(" ").forEach((el) => {
  langs[el.split(":")[0]] = el.split(":")[1];
  locales.push(el.split(":")[0]);
});
export const authAPI = {
  register(firstName, lastName, companyName, countryId, password, email, phone, type) {
    return instance.post("/auth/register", {
      firstName,
      lastName,
      companyName,
      countryId,
      password,
      email,
      phone,
      type
    });
  },
  confirmEmail(token) {
    return instance.post("/auth/confirm-email", { token });
  },
  login(email, password) {
    return instance.post("/auth/login", { email, password });
  },
  resetPassword(email) {
    return instance.post("auth/reset-password", { email });
  },
  logout() {
    return instance.delete("/auth/logout");
  },
  whoAmI() {
    return instance.get("/auth/whoami", {
      headers: {
        "Cache-Control": "no-store, no-cache, max-age=0, must-revalidate, proxy-revalidate"
      }
    });
  },
  resendConfirmationEmail(email) {
    return instance.post("/auth/send-confirm-email", { email })
  },
  setNewPassword(body) {
    return instance.post(`/auth/new-password`, body);
  }
};

export const adminAuthAPI = {
  login(email, password) {
    return instance.post("/auth-admin/login", { email, password }).then((res) => res);
  },
  logout() {
    return instance.delete("/auth-admin/logout").then((res) => res);
  },
  whoAmI() {
    return instance
      .get("/auth-admin/whoami", {
        headers: {
          "Cache-Control": "no-store, no-cache, max-age=0, must-revalidate, proxy-revalidate"
        }
      })
      .then((res) => res);
  }
};
export const profileAPI = {
  getUser(withModerations = false) {
    let url = queryParamseParser("/profile/user", { withModerations });
    return instance.get(url);
  },
  createUser(params) {
    return instance.post("/profile/user", params);
  },
  getCompany(withModerations = false) {
    let url = queryParamseParser("/profile/company", { withModerations });
    return instance.get(url);
  },
  putPassword(password) {
    return instance.put("profile/password", { password });
  },
  putProfile(body) {
    return instance.put("/profile/user", body);
  },
  disableProfile(type) {
    return instance.delete("/profile/disable", { data: { type: type } });
  },
  enableProfile(type) {
    return instance.post("/profile/enable", { type: type });
  },
  updateProfileCompany(body) {
    return instance.put("/profile/company", body);
  },
  getUserById(id) {
    return instance.get(`/profile/${id}`);
  },
  getAllUsers(params) {
    return instance.get("/profile/users", { params });
  },
  getProductsServicesInfo(id) {
    return instance.get(`/profile/products-services-info${id ? `/${id}` : ""}`);
  },
  profileDeactive(id) {
    return instance.post(`profile/deactive/${id}`);
  },
  profileActive(id) {
    return instance.post(`profile/active/${id}`);
  },
  updateFullProfileById(id, data) {
    return instance.put(`profile/${id}`, data);
  },
  getUsersCount() {
    return instance.get("/profile/count");
  },
  searchProfile(userName, hideBlockedUsers) {
    return instance.get(
      `/profile/search/name/${userName}${hideBlockedUsers ? "?showBlocked=false" : ""}`
    );
  }
};
export const draftsAPI = {
  getDraft(qParams = { companyId: null, entityType: "product" }) {
    //entityType:"product" || "service"
    const reqString = queryParamseParser("/moderations/draft", qParams);
    return instance.get(reqString);
  }
};
export const productsAPI = {
  createProduct(
    qParams = { sendModeration: false }, // true || false
    productName,
    keywords,
    description,
    advantage1,
    advantage2,
    advantage3,
    image,
    images,
    video,
    unitPrice,
    fobUnitPrice,
    currencyId,
    minimalAmount,
    deliveryUnitId,
    deliveryTimeId,
    availableCertificates,
    visible,
    deliveryAddress,
    productiveCapacity,
    requirementsPackingDetails,
    subcategories,
    paymentMethods,
    incoterms,
    companyId
  ) {
    // const reqString = queryParamseParser("/products", qParams)
    return instance
      .post("/products", {
        sendModeration: qParams.sendModeration,
        productName,
        keywords,
        description,
        advantage1,
        advantage2,
        advantage3,
        image,
        images,
        video,
        unitPrice,
        fobUnitPrice,
        currencyId,
        minimalAmount,
        deliveryUnitId,
        deliveryTimeId,
        availableCertificates,
        visible,
        deliveryAddress,
        productiveCapacity,
        requirementsPackingDetails,
        subcategories,
        paymentMethods,
        incoterms,
        companyId
      })
      .then((res) => res);
  },
  updateProduct(
    qParams = { sendModeration: false },
    productName,
    keywords,
    description,
    advantage1,
    advantage2,
    advantage3,
    image,
    images,
    video,
    unitPrice,
    fobUnitPrice,
    currencyId,
    minimalAmount,
    deliveryUnitId,
    deliveryTimeId,
    availableCertificates,
    visible,
    deliveryAddress,
    productiveCapacity,
    requirementsPackingDetails,
    subcategories,
    paymentMethods,
    incoterms,
    companyId,
    productId
  ) {
    return instance
      .put(`/products/${productId}`, {
        sendModeration: qParams.sendModeration,
        productName,
        keywords,
        description,
        advantage1,
        advantage2,
        advantage3,
        image,
        images,
        video,
        unitPrice,
        fobUnitPrice,
        currencyId,
        minimalAmount,
        deliveryUnitId,
        deliveryTimeId,
        availableCertificates,
        visible,
        deliveryAddress,
        productiveCapacity,
        requirementsPackingDetails,
        subcategories,
        paymentMethods,
        incoterms,
        companyId
      })
      .then((res) => res);
  },
  deleteProduct(id) {
    return instance.delete(`/products/${id}`).then((res) => res);
  },
  getProducts(
    limit = 10,
    page = 1,
    subcategoryId = "",
    sortColumn = "",
    sortDirection = "",
    companyId,
    withModerations = false
  ) {
    let url = queryParamseParser("/products", {
      limit,
      page,
      companyId,
      subcategoryId,
      sortColumn,
      sortDirection,
      withModerations
    });

    return instance.get(url).then((res) => res);
  },
  getProductById(id, withModerations = false, withEdits = false) {
    let url = queryParamseParser(`/products/${id}`, { withModerations, withEdits });
    return instance.get(url).then((res) => res);
  },
  getSubcategoryById(subcategoryId, limit, pages) {
    return instance.get(`/products/subcategory/${subcategoryId}?limit=${limit}&page=${pages}`);
  },
  getProductsCategories() {
    return instance.get("/products/categories?withCount=true");
  },
  getSubcategoryId(subcategoryId, companyId, limit, page) {
    return instance.get(
      `/products?companyId=${companyId}&subcategoryId=${subcategoryId}&limit=${limit}&page=${page}`
    );
  },
  getProductId(id) {
    return instance.get(`products/${id}`);
  },
  getProductArr(limit, page, companyId, subcategoryId) {
    let url = queryParamseParser("products", { limit, page, companyId, subcategoryId });
    return instance.get(url);
  },
  getCompanyCategories(companyId, subcategoryIds, withModerations, withCount = true) {
    let url = queryParamseParser(`/products/categories`, {
      companyId,
      subcategoryIds,
      withModerations,
      withCount
    });
    return instance.get(url);
  },
  changeProductVisibility(id, visible) {
    return instance.put(`/products/${id}/visible`, { visible });
  }
};
export const serviceAPI = {
  createService(
    params = { sendModeration: false },
    serviceName,
    description,
    subcategories,
    visible,
    advantage1,
    advantage2,
    advantage3,
    image,
    video,
    availableCertificates,
    rangeId,
    keywords,
    images,
    companyId
  ) {
    return instance.post("/services", {
      sendModeration: params.sendModeration,
      serviceName,
      description,
      subcategories,
      visible,
      advantage1,
      advantage2,
      advantage3,
      image,
      video,
      availableCertificates,
      rangeId,
      keywords,
      images,
      companyId
    });
  },
  getServices(
    limit = 10,
    page = 1,
    subcategoryId = "",
    sortColumn = "",
    sortDirection = "",
    companyId,
    withModerations = false
  ) {
    let url = queryParamseParser("/services", {
      limit,
      page,
      subcategoryId,
      sortColumn,
      sortDirection,
      companyId,
      withModerations
    });
    return instance.get(url);
  },
  deleteService(id) {
    return instance.delete(`/services/${id}`);
  },
  getServiceById(id, withModerations = false, withEdits = false) {
    let url = queryParamseParser(`/services/${id}`, { withModerations, withEdits });
    return instance.get(url);
  },
  updateService(
    params = { sendModeration: false },
    serviceName,
    description,
    subcategories,
    visible,
    advantage1,
    advantage2,
    advantage3,
    image,
    video,
    availableCertificates,
    rangeId,
    keywords,
    images,
    serviceId
  ) {
    return instance.put(`/services/${serviceId}`, {
      sendModeration: params.sendModeration,
      serviceName,
      description,
      subcategories,
      visible,
      advantage1,
      advantage2,
      advantage3,
      image,
      video,
      availableCertificates,
      rangeId,
      keywords,
      images
    });
  },
  getServiceSubcategoryById(subcategoryId, limit, pages) {
    return instance.get(`/services/subcategory/${subcategoryId}?limit=${limit}&page=${pages}`);
  },
  getServiceId(id) {
    return instance.get(`/services/${id}`);
  },
  getServiceCategoriesByCompany(companyId, subcategoryIds, withModerations, withCount = true) {
    let url = queryParamseParser(`/services/categories`, {
      companyId,
      subcategoryIds,
      withModerations,
      withCount
    });
    return instance.get(url);
  },
  getServiceSubcategory(limit, page, companyId, subcategoryId) {
    let url = queryParamseParser("/services?", { limit, page, companyId, subcategoryId });
    return instance.get(url);
  },
  getServicesById(subcategoryId, companyId, limit, page) {
    return instance.get(
      `/services?limit=${limit}&page=${page}&companyId=${companyId}&subcategoryId=${subcategoryId}`
    );
  },
  changeServiceVisibility(id, visible) {
    return instance.put(`/services/${id}/visible`, { visible });
  }
};
export const settingsAPI = {
  getSettings() {
    return instance.get("/settings");
  },
  changeSettings(request, meeting, changeMeeting, message, m10, m30, h1, h2, h5, h10, d1, d3) {
    return instance.put("/settings", {
      request,
      meeting,
      changeMeeting,
      message,
      m10,
      m30,
      h1,
      h2,
      h5,
      h10,
      d1,
      d3
    });
  }
};
export const uploadsAPI = {
  uploadVideo(videoFile, config = {}) {
    return instance.post("/upload/video", videoFile, config);
  },
  uploadImage(imageFile) {
    return instance.post("/upload/image", imageFile);
  },
  uploadFile(file) {
    return instance.post("/upload/file", file);
  }
};
export const resourcesAPI = {
  getProductCategories() {
    return instance.get("/resources/product-categories");
  },
  getAnnualTurnoverTypes() {
    return instance.get("/resources/annual-turnover-types");
  },
  getNumberEmployeesTypes() {
    return instance.get("/resources/number-employees-types");
  },
  getPercentageTurnoverTypes() {
    return instance.get("/resources/percentage-turnover-types");
  },
  getServiceCategories() {
    return instance.get("/resources/service-categories").then((res) => res);
  },
  getCurrencies() {
    return instance.get("/resources/currencies").then((res) => res);
  },
  getDeliveryUnits() {
    return instance.get("/resources/delivery-units").then((res) => res);
  },
  getPaymentMethods() {
    return instance.get("/resources/payment-methods").then((res) => res);
  },
  getIncoterms() {
    return instance.get("/resources/incoterms").then((res) => res);
  },
  getDeliveryTime() {
    return instance.get("/resources/delivery-time").then((res) => res);
  },
  getCountries() {
    return instance.get("/resources/countries").then((res) => res);
  },
  getServiceRegions() {
    return instance.get("/resources/service-regions").then((res) => res);
  },
  getProductSubCategories() {
    return instance.get("/resources/product-subcategories").then((res) => res);
  },
  getBusinessType() {
    return instance.get("/resources/business-types");
  },
  getComplaintTopics() {
    return instance.get("/resources/topics-complaints");
  },
  getTechnicalTopics() {
    return instance.get("/resources/topics-technical-problems");
  },
  getAllRoles() {
    return instance.get("/resources/roles");
  },
  getAllComplaintStatuses() {
    return instance.get("resources/complaints-statuses").then((res) => res);
  },
  getAllMeetingsStatuses() {
    return instance.get("/resources/meeting-statuses").then((res) => res);
  },
  getAllTranslationsStatuses() {
    return instance.get("/translators/status");
  },
  getAllFeedbackMainStatuses() {
    return instance.get("resources/feedback-main-statuses");
  },
  getLocalesLanguages() {
    return instance.get("resources/locales");
  },
  getTimezone() {
    return instance.get("resources/time-zones");
  }
};
export const companyAPI = {
  getCompanyByIdInSubcategory(id, pageSize, pages, entityType) {
    return instance.get(
      `company/top-companies?entityType=${entityType}&subcategoryId=${id}&limit=${pageSize}&page=${pages}&disableRandom=true`
    );
  },
  getCompanyId(id) {
    return instance.get(`/company/${id}`);
  },
  postOrderId(id, body) {
    return instance.post(`/company/order/${id}`, body);
  },
  getAllBuyerRequests() {
    return instance.get("/company/all-request-buyer");
  },
  getAllSellerRequests(params) {
    return instance.get("/company/all-request-seller", { params });
  },
  getAccess(id) {
    return instance.patch(`/company/get-access/${id}?isSeller=true`);
  },
  getAllCompaniesWithCategories(qParams = {}) {
    const queryString = queryParamseParser("/company/all", qParams);
    return instance.get(queryString);
  },
  sendToSellers(requestId, companiesIds, qParams = null) {
    let url = queryParamseParser(`/company/send-to-sellers/${requestId}`, qParams);
    return instance.post(url, { companiesIds });
  },
  setManager(companiesIds, managerId) {
    return instance.put("/company/set-manager", { companiesIds, managerId });
  },
  setTariff(companyId, params) {
    return instance.put(`company/${companyId}/set-tariff`, params);
  },
  verifyCompany(companyId, verifiedStatusId) {
    return instance.put(`company/verified/${companyId}`, { verifiedStatusId });
  },
  putShowcase(body, id) {
    return instance.put(`/company/${id}/showcase`, { showcase: body });
  },
  updateCompanyNameOauth(companyName) {
    return instance.put(`/profile/company/google-oauth`, { companyName });
  }
};
export const catalogAPI = {
  getTopCompany(pageSize, page) {
    return instance.get(`/company/top-companies?limit=${pageSize}&page=${page}&disableRandom=true`);
  },
  getAllCompany(limit, page) {
    return instance.get(
      `/company?sortColumn=companyName&sortDirection=ASC&limit=${limit}&page=${page}&types=0,2`
    );
  },
  getAllCompanyById(categoryId, limit, page, entityType) {
    return instance.get(
      `/company/all?categoryId=${categoryId}&entityType=${entityType}&page=${page}&limit=${limit}`
    );
  },
  getTopTenCategory() {
    return instance.get("/resources/top-ten-subcategories");
  },
  getCategories() {
    return instance.get("/services/categories?withCount=true");
  },
  getSevenDaysTop() {
    return instance.get("/resources/seven-days-top");
  },
  test() {
    return instance.get("/resources/countries");
  }
};
export const meetingAPI = {
  createMeetingBuyer(request) {
    return instance.post("/meeting/create-meeting-buyer", request);
  },
  createMeetingSeller(request) {
    return instance.post("/meeting/create-meeting-seller", request);
  },
  getUserMeetings(id) {
    return instance.get("/meeting/user-meetings", { id: 1 });
  },
  getCompanyMeetings(companyId) {
    return instance.get(`/meeting/calendar/${companyId}`);
  },
  getAllMeetingsCountForAdmin(withModeration = "", companyId) {
    return instance.get(
      `/meeting/count${withModeration ? "?isModerated=true" : ""}${companyId ? `&companiesIds=${companyId}` : ""
      }`
    );
  },
  // getAllMeetingsWihtModerationCountForAdmin(typeFilter = "") {
  //   return instance.get(`/company/all-count?typeFilter=${typeFilter}&onlineMeetingModerator=true`);
  // },
  getAllMeetingsForAdmin(params) {
    return instance.get("/meeting/meetings", { params });
  },
  getAllMeetingsWithModerationForAdmin(params) {
    return instance.get("/meeting/meetings", {
      params: { ...params, onlineMeetingModerator: true }
    });
  },
  getMeetingInfoForModerator({ meetingId }) {
    return instance.get(`/meeting/meetings-moderator/${meetingId}`);
  },
  getMeetingDetails(meetingId) {
    return instance.get(`/meeting/meeting/${meetingId}`);
  },
  getCompanyMeetingsCount(companyId, withModeration) {
    return instance.get(
      `/meeting/count/company/${companyId}${withModeration ? "?isModerated=true" : ""}`
    );
  },
  getAllMeetingsBuyer(params) {
    return instance.get(`/meeting/all-meetings-buyer`, {
      params
    });
  },
  getAllMeetingsSeller(params) {
    return instance.get(`/meeting/all-meetings-seller`, {
      params
    });
  },
  confirmMeetingById(id) {
    return instance.patch(`/meeting/meeting-confirm/${id}`);
  },
  cancelMeetingById(id) {
    return instance.patch(`/meeting/meeting-cancel/${id}`);
  },
  updateMeetingStatus(id, statusId) {
    return instance.put(`/meeting/meeting/${id}`, { statusId });
  },
  connectToMeeting(id) {
    return instance.post(`/meeting/connect`, { meetingId: id });
  },
  getMeetingStatus(meetingId) {
    return instance.get(`meeting/status/${meetingId}`).then((res) => res.data);
  }
};

export const tariffsAPI = {
  getTariffs() {
    return instance.get("/tariffs");
  },
  getDescriptionTariffs() {
    return instance.get("/tariffs/descriptions");
  },
  postDescriptionTariffs(body) {
    return instance.post("/tariffs/descriptions", body);
  },
  getCreditsServices() {
    return instance.get("/tariffs/credits-services");
  },
  getServices() {
    return instance.get("/tariffs/services");
  },
  postServices(body) {
    return instance.post("/tariffs/services", body);
  },
  putServices(body, serviceId) {
    return instance.put(`/tariffs/services/${serviceId}`, body);
  },
  getUserTariffs() {
    return instance.get("/tariffs/user");
  },
  putCreditsServices(body, serviceId) {
    return instance.put(`/tariffs/credits-services/${serviceId}`, body);
  },
  deleteServices(serviceId) {
    return instance.delete(`/tariffs/services/${serviceId}`);
  },
  deleteCreditsServices(serviceId) {
    return instance.delete(`/tariffs/credits-services/${serviceId}`);
  },
  deleteDescriptionTariff(descriptionId) {
    return instance.delete(`/tariffs/descriptions/${descriptionId}`);
  },
  putDescriptionTariff(descriptionId, body) {
    return instance.put(`/tariffs/descriptions/${descriptionId}`, body);
  },
  putTariffsPrice(id, cost) {
    return instance.put(`/tariffs/${id}`, { cost });
  },
  getServiceById(serviceId) {
    return instance.get(`/tariffs/services/${serviceId}`);
  }
};

export const searchAPI = {
  searchProducts(q = null) {
    return instance.get(`/search?entityType=products&q=${q}`);
  },
  searchServices(q) {
    return instance.get(`/search?entityType=services&q=${q}`);
  },
  searchPromocodeByName(name = "") {
    return instance.get(`promocodes/search/${name}`);
  },
  searchCompaniesByName(name) {
    return instance.get(`/company/name/${name}`);
  },
  searchUsersByFullName(name) {
    return instance.get(`profile/search/name/${name}`);
  },
  searchEmployeesByFullName(name) {
    return instance.get(`admins/managers/search?name=${name}`);
  },
  searchTranslationCompanyName(fromCompanyName) {
    return instance.get(
      `/feedback/main/search?${fromCompanyName ? `fromCompanyName=${fromCompanyName}` : ``}`
    );
  }
};

export const mailTemplateAPI = {
  getExporterIncomingRequestTemplate(lang) {
    return instance.get(`/mail-template/incoming-request-to-exporter?lang=${lang}`);
  },
  updateExporterRequestTemplate(lang, template, subject) {
    return instance.put("/mail-template/incoming-request-to-exporter", {
      lang,
      subject,
      template
    });
  },
  getEmailConfirmationMailTemplate(lang) {
    return instance.get(`/mail-template/confirm-email?lang=${lang}`);
  },
  updateEmailConfirmationMailTemplate(lang, template, subject) {
    return instance.put("/mail-template/confirm-email", { lang, template, subject });
  },
  getPasswordRecoveryTemplate(lang) {
    return instance.get(`/mail-template/password-reset?lang=${lang}`);
  },
  updatePasswordRecoveryTemplate(lang, template, subject) {
    return instance.put("/mail-template/password-reset", { lang, template, subject });
  },
  getMeetingInvitationTemplate(lang) {
    return instance.get(`/mail-template/meeting-invitation-letter?lang=${lang}`);
  },
  updateMeetingInvitationTemplate(lang, template, subject) {
    return instance.put("/mail-template/meeting-invitation-letter", {
      lang,
      template,
      subject
    });
  },
  getMeetingInvitationModerTemplate(lang) {
    return instance.get(`/mail-template/moderation-meeting-invitation-letter?lang=${lang}`);
  },
  updateMeetingInvitationModerTemplate(lang, template, subject) {
    return instance.put("/mail-template/moderation-meeting-invitation-letter", {
      lang,
      template,
      subject
    });
  },
  getMeetingInvitationFromSellerTemplate(lang) {
    return instance.get(`/mail-template/meeting-invitation-letter-buyer?lang=${lang}`);
  },
  updateMeetingInvitationFromSellerTemplate(lang, template, subject) {
    return instance.put("/mail-template/meeting-invitation-letter-buyer", {
      lang,
      template,
      subject
    });
  },
  getMeetingInvitationFromSellerModerTemplate(lang) {
    return instance.get(`/mail-template/moderation-meeting-invitation-letter-buyer?lang=${lang}`);
  },
  updateMeetingInvitationFromSellerModerTemplate(lang, template, subject) {
    return instance.put("/mail-template/moderation-meeting-invitation-letter-buyer", {
      lang,
      template,
      subject
    });
  },
  getPaymentConfirmationMailTemplate(lang) {
    return instance.get(`/mail-template/confirm-payment?lang=${lang}`);
  },
  updatePaymentConfirmationMailTemplate(lang, template, subject) {
    return instance.put("/mail-template/confirm-payment", {
      lang,
      template,
      subject
    });
  },
  getBuyerAndSellerFeedbackMailTemplate(lang) {
    return instance.get(`/mail-template/feedback?lang=${lang}`);
  },
  updateBuyerAndSellerFeedbackMailTemplate(lang, template, subject) {
    return instance.put("/mail-template/feedback", {
      lang,
      template,
      subject
    });
  },
  getRejectedChangesNotificationMailTemplate(lang) {
    return instance.get(`/mail-template/content-moderation?lang=${lang}`);
  },
  updateRejectedChangesNotificationMailTemplate(lang, template, subject) {
    return instance.put("/mail-template/content-moderation", {
      lang,
      template,
      subject
    });
  },
  getMeetingNotificationMailTemplate(lang) {
    return instance.get(`/mail-template/meeting-notice?lang=${lang}`);
  },
  updateMeetingNotificationMailTemplate(lang, template, subject) {
    return instance.put("/mail-template/meeting-notice", {
      lang,
      template,
      subject
    });
  }
};

export const mailAPI = {
  sendEmail(data) {
    return instance.post("/mail/send", data);
  }
};

export const feedbackAPI = {
  updateFeedbackStatus(feedbackId, statusId) {
    return instance.put(`/feedback/main/${feedbackId}`, { statusId });
  },
  sendFeedback(content) {
    return instance.post("/feedback", { ...content });
  },
  getFeedback() {
    return instance.get("/feedback");
  },
  sendMatchMakingRequest(data) {
    return instance.post("/matchmaking/matchmaking", { ...data });
  },
  feedbackMain(sendParams = {}) {
    return instance.post("/feedback/main", sendParams);
  },
  searchFeedbackMain(fromCompanyName) {
    return instance.get(
      `/feedback/main/search?${fromCompanyName ? `fromCompanyName=${fromCompanyName}` : ``}`
    );
  },
  changeGeneralFeedbackStatusById(id, statusId) {
    return instance.put(`/feedback/general/${id}`, { statusId: statusId });
  },
  getFeedbackMain(qParams) {
    const queryString = queryParamseParser("/feedback/main", qParams);
    return instance.get(queryString);
  },
  getFeedbackMainById(id) {
    return instance.get(`/feedback/main/${id}`);
  },
  getFeedbackGeneralById(id) {
    return instance.get(`/feedback/general/${id}`);
  },
  getFeedbackMainCount() {
    return instance.get("/feedback/main/count");
  },
  getFeedbackCount() {
    return instance.get("/feedback/general/count");
  },
  feedbackGeneral(data) {
    return instance.post("/feedback/general", { ...data });
  },
  getFeedbackGeneral(qParams) {
    const queryString = queryParamseParser("/feedback/general", qParams);
    return instance.get(queryString);
  },
  noMatchSearchRequestsInfo() {
    return instance.get("/feedback/count");
  },
  noMatchSearchRequestsData(qParams = {}) {
    let url = queryParamseParser("/feedback", qParams);
    // return instance.get("/feedback?source=search");
    return instance.get(url);
  },
  rejectMatchSearchRequest(id) {
    return instance.post(`/feedback/reject/${id}`);
  },
  getFeedBackStatuses() {
    return instance.get("feedback/statuses");
  },
  getCompaniesWithRequestNames(companyName) {
    return instance.get(`feedback/search?companyName=${companyName}`);
  },
  updateNoMatchSearchRequestsStatus(id, statusId) {
    return instance.put(`feedback/${id}`, { statusId });
  }
};

export const complaintsAPI = {
  getAllComplaints(limit = 10, page = 1) {
    return instance.get(`/complaints?limit=${limit}&page=${page}`).then((res) => res);
  },
  createComplaint(linkProductService = "", linkCompany = "", topicComplaint, message, files = []) {
    return instance.post("/complaints", {
      linkProductService,
      linkCompany,
      topicComplaint,
      message,
      files
    });
  },
  getAllComplaintsAdmin(qParams) {
    const queryString = queryParamseParser("/complaints/all", qParams);
    return instance.get(queryString);
  },
  searchComplaint(param) {
    return instance.get(`/complaints/search?${param}`).then((res) => res);
  },
  complaintById(id) {
    return instance.get(`/complaints/${id}`).then((res) => res);
  },
  updateComplaintById(id, status, answer) {
    return instance.put(`/complaints/${id}`, { status, answer }).then((res) => res);
  }
};

export const notesAPi = {
  createNote(targetId, text) {
    return instance.post("/notes", { targetId, text });
  },
  getNotes(companyId, targetId, currentPage) {
    return instance.get(
      `/notes/company/${companyId}/target/${targetId}${currentPage ? `?limit=3&page=${currentPage}` : ``
      }`
    );
  },
  deleteNote(noteId) {
    return instance.delete(`/notes/${noteId}`);
  },
  updateNote(noteId, text) {
    return instance.put(`/notes/${noteId}`, { text });
  }
};

export const ordersAPI = {
  getOrders(qParams) {
    let queryStr = queryParamseParser("/orders", qParams);
    return instance.get(queryStr);
  },
  getOneOrder(id) {
    return instance.get(`/orders/${id}`);
  },
  adminGetOrdersCount() {
    return instance.get("/orders/count");
  },
  getCompanyOrdersCount(id) {
    return instance.get(`/orders/count/company/${id} `);
  },
  getOrdersStatuses() {
    return instance.get("/orders/statuses");
  },
  searchOrders(params) {
    return instance.get(`/orders?${params}`);
  }
};

export const inquiriesAPI = {
  makeAdminInquiry(companyId, fileUrl, name, price, paymentTypeId) {
    const data = { companyId, paymentTypeId, name, price };
    if (fileUrl) data.fileUrl = fileUrl;
    return instance.post("/inquiries/admin", data);
  },
  makeInquiry(
    companyId,
    // promocodeId = null,
    promocode = "",
    fileUrl = null,
    platformServiceId,
    platformServiceType,
    paymentTypeId
  ) {
    const data = { companyId, platformServiceId, platformServiceType, paymentTypeId };
    if (fileUrl) data.fileUrl = fileUrl;
    if (promocode) data.promocode = promocode;
    // if(promocodeId) data.promocodeId = promocodeId;
    return instance.post("/inquiries", data);
  },
  getInquiriesCount() {
    return instance.get("/inquiries/count");
  },
  getInquiriesCountById(id) {
    return instance.get(`/inquiries/count/company/${id}`);
  },
  getInquiries({ limit, page, companyId, params }) {
    return instance.get(
      `/inquiries?limit=${limit}&page=${page}&${params}${companyId ? `&companiesIds=${companyId}` : ""
      } `
    );
  },
  getInquiriesStatuses() {
    return instance.get("/inquiries/statuses");
  },
  getInquiryById(id) {
    return instance.get(`/inquiries/${id}`);
  },
  getInquiriesByCompanyId(id) {
    return instance.get(`/inquiries/company/${id}`);
  },
  cancelInquiryById(id) {
    return instance.put(`/inquiries/${id}/cancel`);
  },
  changeInquiryStatusById({ inquiryId, statusId }) {
    return instance.patch(`inquiries/${inquiryId}`, { statusId });
  }
};

export const promotionAPI = {
  getPromotionProduct(id, page, limit) {
    return instance.get(`promotion/products/promoted/${id}?page=${page}&limit=${limit}`);
  },
  getPromotionInfo() {
    return instance.get("/promotion/info");
  },
  getPromotionsInfoForAdmin(params) {
    return instance.get("/promotion/all", { params });
  },
  getPromotionInfoForAdmin(id) {
    return instance.get(`/promotion/${id}`);
  },
  addProductPromotion(id, productId) {
    return instance.post(`promotion/products/${id}`, { productId });
  },
  removeProductPromotion(id) {
    return instance.delete(`promotion/products/${id}`);
  },
  addServicePromotion(id, serviceId) {
    return instance.post(`promotion/services/${id}`, { serviceId });
  },
  removeServicePromotion(id) {
    return instance.delete(`promotion/services/${id}`);
  },
  getProductPromotionPossibilities(id, page = 1, limit = 10, search = "", subcategoryId = "") {
    let url = queryParamseParser(`promotion/products/${id}`, {
      page,
      limit,
      search,
      subcategoryId
    });
    return instance.get(url);
  },
  getServicePromotionPossibilities(id, page = 1, limit = 10, search = "", subcategoryId = "") {
    let url = queryParamseParser(`promotion/services/${id}`, {
      page,
      limit,
      search,
      subcategoryId
    });
    return instance.get(url);
  },
  getServicePromotion(id, page, limit) {
    return instance.get(`/promotion/services/promoted/${id}?page=${page}&limit=${limit}`);
  }
};

export const techIssuesAPI = {
  createTechIssue(message = "", topicTechnicalProblem = "", files = []) {
    return instance.post("/technical-problems", {
      message,
      topicTechnicalProblem,
      files
    });
  },
  getAllTechIssue(limit = 10, page = 1) {
    return instance.get(`/technical-problems?limit=${limit}&page=${page}`).then((res) => res);
  },
  getAdminTechProblems(params) {
    return instance.get(`/technical-problems/all${params ? params : ""}`);
  },
  getTechIssueById(id) {
    return instance.get(`technical-problems/${id}`);
  },
  updateTechIssueById(id, status, answer) {
    return instance.put(`/technical-problems/${id}`, { status, answer }).then((res) => res);
  }
};

export const promocodesAPI = {
  getPromocodes(qParams = {}) {
    let queryStr = queryParamseParser("/promocodes", qParams);
    return instance.get(queryStr);
  },
  addNewPromoCode({ name, discount, dateStart, dateEnd, possibleUses }) {
    return instance.post("/promocodes", { name, discount, dateStart, dateEnd, possibleUses });
  },
  deactivatePromoCodeById(id) {
    return instance.delete(`/promocodes/${id}`);
  }
};

export const subscribeAPI = {
  getAccountsCount() {
    return instance.get("company/tariffs/count");
  },
  getAccounts(params = {}) {
    return instance.get(`company/tariffs`, { params });
  }
};

export const employeesAPI = {
  getEmployees() {
    return instance.get("/admins/count");
  },
  getEmployeesTable(qParams) {
    const queryString = queryParamseParser("/admins/managers", qParams);
    return instance.get(queryString);
  },
  addUser(roleId, firstName = "", lastName = "", email = "", password = "", phoneNumber = "") {
    return instance.post("/admins", {
      roleId,
      firstName,
      lastName,
      email,
      password,
      phoneNumber
    });
  },
  changePassword(adminId, password = "") {
    return instance.put(`/admins/${adminId}`, { password });
  },
  deleteUser(adminId) {
    return instance.delete(`/admins/${adminId}`, { adminId });
  },
  restoreUser(adminId) {
    return instance.post(`/admins/restore/${adminId}`);
  },
  search(name) {
    return instance.get(`admins/managers/search?name=${name}&withPagination=true`);
  }
};
export const communicationHistoryAPI = {
  getHistory(entityType, entityId, offset, limit = 10, side) {
    let queryStr = `/communication-history?offset=${offset}&limit=${limit}`;
    if (entityType) queryStr += `&entityType=${entityType}`;
    if (entityId) queryStr += `&entityId=${entityId}`;
    if (side) queryStr += `&side=${side}`;
    return instance.get(queryStr);
  },
  addCommentToHistory(entityId, entityType, email, message, side, isComment = true) {
    return instance.post("/communication-history", {
      entityId,
      entityType,
      email,
      message,
      side,
      isComment
    });
  }
};
export const promoCodesAPI = {
  getPromocodes() {
    return instance.get("/promocodes");
  },
  checkPromoCode(promocodeName) {
    return instance.get(`/promocodes/name/${promocodeName}`);
  }
};
export const dialogsAPI = {
  getMessages(room, limit, offset) {
    return instance.get(`dialogue/messages/room/${room}?limit=${limit}&offset=${offset}`);
  },
  getUserRoom(params) {
    return instance.get(`/dialogue/rooms`, { params });
  },
  getWSRoomId({ companyId = null, admin = null }) {
    let queryStr = "dialogue/room";
    if (companyId) queryStr += `?companyId=${companyId}`;
    return instance.get(queryStr);
  }
};

export const translatorsAPI = {
  getTranslators() {
    return instance.get("/translators/all-translators");
  },
  getTranslationCount() {
    return instance.get("/translators/count");
  },
  updateTranslationStatus(id, status) {
    return instance.put("/translators/status", { id, status });
  }
};

export const notificationsAPI = {
  getNotifications() {
    return instance.get("/notifications");
  }
};
export const notificationsFeedAPI = {
  getUnreadNotificationsCount() {
    return instance.get("/notifications/count", {
      headers: {
        "Cache-Control": "no-store, no-cache, max-age=0, must-revalidate, proxy-revalidate"
      }
    });
  },
  getNotifications(side, offset = 0, limit = 10) {
    // SELLER | BUYER
    return instance.get(`/notifications/feed?side=${side}&offset=${offset}&limit=${limit}`, {
      headers: {
        "Cache-Control": "no-store, no-cache, max-age=0, must-revalidate, proxy-revalidate"
      }
    });
  },
  markNotificationsAsRead(idsArr = []) {
    return instance.patch("/notifications/mark-read", { ids: idsArr });
  },
  markNotificationsAsReadNew(idsArr = [], type) {
    return instance.patch("/notifications/mark-read", { ids: idsArr, type: type });
  }
};
export const matchmakingAPI = {
  getMatchMakingCount() {
    return instance.get("/matchmaking/count");
  },
  getAllMatchMakingRequests(params = {}) {
    return instance.get("/matchmaking", { params });
  },
  getMatchMakingById(id) {
    return instance.get(`/matchmaking/${id}`);
  },
  searchSupport(name = "", email = "") {
    return instance.get(`/matchmaking/support-search?name=${name}&email=${email}`);
  },
  changeMatchMakingStatusById(id, statusId) {
    return instance.put(`/matchmaking/${id}`, { statusId: statusId });
  },
  getMatchMakingStatuses() {
    return instance.get("/matchmaking/statuses");
  },
  searchCompaniesByName(companyName) {
    return instance.get(`/matchmaking/search?companyName=${companyName}`);
  }
};
export const platformSettings = {
  getMailFrom() {
    return instance.get("/platform-settings?key=MAIL_FROM");
  },
  updateMailFrom(value = "") {
    return instance.put("/platform-settings", {
      key: "MAIL_FROM",
      value
    });
  }
};

export const moderationChangesAPI = {
  getAllModerations(params = {}) {
    return instance.get("/moderations", { params });
  },
  moderationSearch() {
    return instance.get("/moderations/search");
  },
  moderationCount() {
    return instance.get("/moderations/count");
  },
  getModerationById(id) {
    return instance.get(`/moderations/${id}`);
  },
  rejectModerationById(id, reason = {}, statusId) {
    return instance.put(`/moderations/${id}`, statusId > 1 ? { statusId } : { ...reason });
  },
  changeModerationById(id, statusId) {
    return instance.put(`/moderations/${id}`, { statusId });
  },
  getModerationsStatuses() {
    instance.get("/moderations/resources/statuses");
  },
  viewModarationLink(id) {
    return instance.patch(`/moderations/${id}/viewed`);
  }
};
